<template>
  <div>
    <el-page-header
        content="添加用户"
        icon=""
        title="用户管理"
    />

    <el-form
        ref="userFormRef"
        :model="userForm"
        :rules="userFormRules"
        label-width="80px"
        class="ruleForm"
    >
      <el-form-item
          label="用户名"
          prop="username"
      >
        <el-input v-model="userForm.username"/>
      </el-form-item>

      <el-form-item
          label="昵称"
          prop="nickname"
      >
        <el-input v-model="userForm.nickname"/>
      </el-form-item>

      <el-form-item
          label="密码"
          prop="password"
      >
        <el-input
            v-model="userForm.password"
            type="password"
        />
      </el-form-item>

      <el-form-item
          label="角色"
          prop="role"
      >
        <el-select
            v-model="userForm.role"
            class="m-2"
            placeholder="Select"
            style="width:100%"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
          label="职责"
          prop="responsibility"
      >
        <el-input
            v-model="userForm.responsibility"
            type="textarea"
        />
      </el-form-item>

      <el-form-item
          label="头像"
          prop="avatar"
      >
        <Upload
            :avatar="userForm.avatar"
            @change="handleChange"
        />
      </el-form-item>

      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm()"
        >添加用户
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import {ref, reactive} from "vue";
import Upload from "@/components/upload/Upload";
import { uploadToAliyun } from '@/utils/uploadService.js';
import {useRouter} from 'vue-router'
import axios from "axios";
import {ElMessage} from "element-plus";
const router = useRouter()

const userFormRef = ref();
const userForm = reactive({
  username: "",
  nickname: "",
  password: "",
  gender: 0, //保密
  role: 3, // 管理员1，总编辑2，编辑3，纪检监察4，普通用户5
  responsibility: "",
  avatar: "",
  logins: 0,
});

const userFormRules = reactive({
  username: [{required: true, message: "请输入名字", trigger: "blur"}],
  password: [{required: true, message: "请输入密码", trigger: "blur"}],
  role: [{required: true, message: "请选择权限", trigger: "blur"}],
  responsibility: [{required: true, message: "请输入介绍", trigger: "blur"}],
  avatar: [{required: true, message: "请上传头像", trigger: "blur"}]
});

const options = [
  {label: "管理员", value: 1},
  {label: "总编辑", value: 2},
  {label: "编辑", value: 3},
  {label: "纪检监察", value: 4},
  {label: "普通用户", value: 5}
];

//每次选择完图片之后的回调
const handleChange = file => {
  userForm.avatar = URL.createObjectURL(file);
  userForm.file = file;
};

const baseUrl = process.env.VUE_APP_API_BASE_URL

// 提交表单
const submitForm = async () => {
  userFormRef.value.validate(async (valid) => {
    if (valid) {
      // 更新 userForm 中的文件路径为阿里云返回的文件 URL
      userForm.avatar = await uploadToAliyun('useruploads', userForm.file);

      // 添加用户数据
      await axios.post(baseUrl + "/adminapi/user/add", userForm);
      ElMessage.success('用户添加成功！');
      // 跳转到用户列表页
      await router.push(`/user-manage/userlist`);
    }
  });
}
</script>

<style lang="scss" scoped>
.ruleForm {
  margin-top: 20px;
}
</style>
