<template>
  <div>
    <el-page-header
        content="人员列表"
        icon=""
        title="人员管理"
    />
    <el-card class="table-container">
      <el-table
          :data="tableData"
          style="width: 100%;"
      >
        <el-table-column
            prop="name"
            label="姓名"
        />

        <el-table-column
            prop="position"
            label="职务"
        />

        <el-table-column
            label="头像"
            width="100"
        >
          <template #default="scope">
            <div v-if="scope.row.avatar">
              <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
            </div>
            <div v-else>
              <el-avatar :size="50" :src="getDefaultAvatar(scope.row.gender)"></el-avatar>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="性别">
          <template #default="scope">
            {{ categoryFormat(scope.row.gender) }}
          </template>
        </el-table-column>


        <el-table-column label="入职时间">
          <template #default="scope">
            {{ formatTime.getTime(scope.row.hireDate).slice(0, 10) }}
          </template>
        </el-table-column>

        <el-table-column label="在职状况">
          <template #default="scope">
            {{ employmentStatusFormat(scope.row.employmentStatus) }}
          </template>
        </el-table-column>

        <el-table-column label="是否使用">
          <template #default="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleSwitchChange(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button
                circle
                :icon="Star"
                type="success"
                @click="handlePreview(scope.row)"
            ></el-button>
            <el-button
                circle
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>

            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    circle
                    :icon="Delete"
                    type="danger"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
        v-model="dialogVisible"
        title="人员预览"
        width="58%"
    >
      <div>
        <h2>{{ previewData.name + " " + previewData.position }}</h2>
        <div style="font-size:12px;color:gray;">{{ "最后更新：" + formatTime.getTime(previewData.updatedAt) }}</div>

        <el-divider>
          <el-icon>
            <star-filled/>
          </el-icon>
        </el-divider>

        <div class="htmlcontent">
          <div>
            <strong>性别：</strong> {{ categoryFormat(previewData.gender) }}
          </div>
          <div>
            <strong>电话：</strong> {{ previewData.phone }}
          </div>
          <div>
            <strong>邮箱：</strong> {{ previewData.email }}
          </div>
          <div>
            <strong>地址：</strong> {{ previewData.address }}
          </div>
          <div>
            <strong>入职时间：</strong> {{ formatTime.getTime(previewData.hireDate).slice(0, 10) }}
          </div>
          <div>
            <strong>学历：</strong> {{ previewData.education }}
          </div>
          <div>
            <strong>个人简介：</strong> {{ previewData.biography }}
          </div>
          <div>
            <strong>备注：</strong> {{ previewData.notes }}
          </div>
          <div>
            <strong>在职状态：</strong> {{ employmentStatusFormat(previewData.employmentStatus) }}
          </div>
          <div>
            <strong>使用状态：</strong> {{ previewData.status === 1 ? "使用" : "停用" }}
          </div>
          <div>
            <strong>创建时间：</strong> {{ formatTime.getTime(previewData.createdAt) }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import {getDefaultAvatar} from "@/utils/getDefaultAvatar";
import formatTime from "@/utils/formatTime";
import {Star, Edit, Delete, StarFilled} from "@element-plus/icons-vue";
import {useRouter} from 'vue-router'

const router = useRouter()
const tableData = ref([]);
const previewData = ref({});
const dialogVisible = ref(false);

onMounted(() => {
  getTableData();
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(baseUrl + `/adminapi/staff/list`);
  tableData.value = res.data.data;
};

// 格式化分类信息
const categoryFormat = category => {
  const arr = ["保密", "男", "女"];
  return arr[category];
};

// 格式化在职状态信息
const employmentStatusFormat = category => {
  const arr = ["在职", "借调", "休假", "退休", "死亡"];
  return arr[category];
};

// 开关回调
const handleSwitchChange = async item => {
  await axios.put(baseUrl + `/adminapi/staff/publish`, {
    _id: item._id,
    status: item.status
  });
  await getTableData();
};

// 预览回调
const handlePreview = async data => {
  const res = await axios.get(`${baseUrl}/adminapi/staff/list/${data._id}`)
  previewData.value = res.data.data[0]
  dialogVisible.value = true
}

// 删除回调
const handleDelete = async (item) => {
  await axios.delete(`${baseUrl}/adminapi/staff/del/${item._id}`)
  await getTableData()
}

// 编辑回调
const handleEdit = (item) => {
  //跳转到/staff-manage/editstaff/:id
  router.push(`/staff-manage/editstaff/${item._id}`)
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 10px;
}

.table-container {
  margin-top: 20px;

  :deep(.el-card__body) {
    padding: 10px 20px 20px 20px;
  }
}

.htmlcontent {
  div {
    margin-bottom: 10px;
    font-size: 15px;
  }
}

::v-deep(.htmlcontent) {
  img {
    max-width: 100%;
  }
}
</style>
