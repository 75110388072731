<template>
  <div>
    <el-page-header
        content="专题列表"
        icon=""
        title="新闻管理"
    />
    <el-card class="table-container">
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column prop="category" label="专题分类" width="80"/>
        <el-table-column prop="title" label="专题标题" width="580"/>
        <el-table-column label="专题封面">
          <template #default="scope">
            <el-image
                v-if="scope.row.cover"
                :src="scope.row.cover"
                style="width: 180px; height: 90px;"
                fit="cover"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                v-if="scope.row.username !== 'system'"
                size="small"
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {Edit} from "@element-plus/icons-vue";

const router = useRouter();
const tableData = ref([]);
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(`${baseUrl}/adminapi/newsHot/list`);
  tableData.value = res.data.data;
};

const handleEdit = (item) => {
  router.push(`/news-manage/newsHotEdit/${item._id}`);
};

onMounted(getTableData);
</script>

<style lang="scss" scoped>
.table-container {
  margin-top: 20px;

  :deep(.el-card__body) {
    padding: 10px 20px 20px 20px;
  }
}
</style>
