<!-- 热点专题添加 -->
<template>
  <el-page-header
      content="创建专题"
      icon=""
      title="新闻管理"
      style="margin-bottom: 20px;"
  />

  <NewsHotForm/>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import NewsHotForm from "@/views/news-manage/NewsHotForm.vue";

const router = useRouter()
const route = useRoute()
</script>

<style lang="scss" scoped>

</style>