//*
* 新闻列表
* 后期增加发送后端请求时限定返回数据，角色为管理员和总编辑，可以获取全部新闻列表，其它角色只能获取自己的新闻列表
* 后期增加管理员和总编辑可以修改分类、是否发布和删除新闻，其它角色只能浏览新闻和修改、删除自己的新闻
* */
<template>
  <div>
    <el-page-header
        content="新闻列表"
        icon=""
        title="新闻管理"
    />

    <el-card class="table-container">
      <el-table
          :data="tableData"
          style="width: 100%;"
      >
        <el-table-column
            prop="title"
            label="标题"
        />
        <el-table-column label="热点专题" width="126">
          <template #default="scope">
            <el-select
                v-model="scope.row.hot"
                placeholder="请选择专题"
                @change="handleHotChange(scope.row)"
            >
              <el-option
                  v-for="(item, index) in hotList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="分类" width="126">
          <template #default="scope">
            <el-select
                v-model="scope.row.category"
                placeholder="请选择分类"
                @change="handleCategoryChange(scope.row)"
            >
              <el-option
                  v-for="(item, index) in categories"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
            prop="author"
            label="作者"
            width="142"
        />

        <el-table-column label="更新时间" width="145">
          <template #default="scope">
            {{ formatTime.getTime(scope.row.editTime) }}
          </template>
        </el-table-column>

        <el-table-column label="发布时间" width="145">
          <template #default="scope">
            {{ scope.row.publicationTime ? formatTime.getTime(scope.row.publicationTime) : '' }}
          </template>
        </el-table-column>

        <el-table-column label="是否发布" width="82">
          <template #default="scope">
            <el-switch
                v-model="scope.row.isPublish"
                :active-value="1"
                :inactive-value="0"
                @change="handleSwitchChange(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button
                circle
                :icon="Star"
                type="success"
                @click="handlePreview(scope.row)"
            ></el-button>
            <el-button
                circle
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>

            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    circle
                    :icon="Delete"
                    type="danger"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>


<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import formatTime from "@/utils/formatTime";
import {Star, Edit, Delete} from "@element-plus/icons-vue";
import {useRouter} from 'vue-router';

const router = useRouter();
const tableData = ref([]);
const categories = [
  {value: 1, label: '最新动态'},
  {value: 2, label: '纪检监察'},
  {value: 3, label: '通知公告'},
  {value: 4, label: '先锋圳上'},
  {value: 5, label: '工作动态'},
  {value: 6, label: '政府工作报告'}
];

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 初始化热点专题选项
const hotList = ref([]);

// 获取热点专题数据的函数
const fetchHotList = async () => {
  try {
    const res = await axios.get(`${baseUrl}/adminapi/newsHot/title`);
    if (res.data.data && res.data.data.length > 0) {
      hotList.value = [
        {label: "请选择热点专题", value: 0}, // 默认选项
        ...res.data.data.map(item => ({
          label: item.title,
          value: item.category, // 假设后端返回的热点标题有 id 和 title 字段
        }))
      ];
    }
  } catch (error) {
    console.error('获取热点专题数据失败:', error);
  }
};

const getTableData = async () => {
  const res = await axios.get(baseUrl + `/adminapi/news/list`);
  tableData.value = res.data.data;
};

// 开关回调
const handleSwitchChange = async item => {
  await axios.put(baseUrl + `/adminapi/news/publish`, {
    _id: item._id,
    isPublish: item.isPublish
  });
};

// 热点专题更改回调
const handleHotChange = async (row) => {
  await axios.put(`${baseUrl}/adminapi/news/hot`, {
    _id: row._id,
    hot: row.hot
  });
};

// 分类更改回调
const handleCategoryChange = async (row) => {
  await axios.put(`${baseUrl}/adminapi/news/category`, {
    _id: row._id,
    category: row.category
  });
};

// 预览回调
const handlePreview = (item) => {
  router.push(`/news-manage/newsPreview/${item._id}`);
};

// 编辑回调
const handleEdit = (item) => {
  router.push(`/news-manage/editnews/${item._id}`);
}

// 删除回调
const handleDelete = async (item) => {
  await axios.delete(`${baseUrl}/adminapi/news/del/${item._id}`);
  await getTableData();
};

onMounted(() => {
  fetchHotList();
  getTableData();
});
</script>

<style lang="scss" scoped>
.table-container {
  margin-top: 20px;

  :deep(.el-card__body) {
    padding: 10px 20px 20px 20px;
  }
}
</style>
