<template>
  <div class="container">
    <el-page-header
        content="图片列表"
        icon=""
        title="图片管理"
    />

    <el-card class="table-container">
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column prop="position" label="图片位置" width="80"/>
        <el-table-column prop="order" label="图片顺序" width="80"/>
        <el-table-column prop="title" label="图片标题" width="580"/>
        <el-table-column label="轮播图片">
          <template #default="scope">
            <el-image
                v-if="scope.row.cover"
                :src="scope.row.cover"
                style="width: 180px; height: 90px;"
                fit="cover"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                v-if="scope.row.username !== 'system'"
                size="small"
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>
            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                v-if="scope.row.username!== 'system'"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    size="small"
                    type="danger"
                    :icon="Delete"
                    v-if="scope.row.username!== 'system'"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {Delete, Edit} from "@element-plus/icons-vue";

const router = useRouter();
const tableData = ref([]);
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(`${baseUrl}/adminapi/carousel/list`);
  tableData.value = res.data.data;
};

// 编辑回调
const handleEdit = (item) => {
  router.push(`/picture-manage/carouselEdit/${item._id}`);
};

// 删除回调
const handleDelete = async (item) => {
  const res = await axios.delete(`${baseUrl}/adminapi/carousel/del/${item._id}`);
  if (res.data.ActionType === "OK") {
    ElMessage.success("删除成功");
  }
  await getTableData();
};

onMounted(getTableData);
</script>

<style lang="scss" scoped>
.table-container {
  margin-top: 20px;

  :deep(.el-card__body) {
    padding: 10px 20px 20px 20px;
  }
}
</style>
