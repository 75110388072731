模板
<template>
  <div>
    <el-page-header
        content="编辑人员"
        @back="handleBack()"
        title="人员管理"
    />

    <el-form
        ref="staffFormRef"
        :model="staffForm"
        :rules="staffFormRules"
        label-width="100px"
        class="ruleForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                  label="姓名"
                  prop="name"
              >
                <el-input v-model="staffForm.name"/>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="职务"
                  prop="position"
              >
                <el-input
                    v-model="staffForm.position"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                  label="性别"
                  prop="gender"
              >
                <el-select
                    v-model="staffForm.gender"
                    class="m-2"
                    placeholder="请选择"
                    style="width:100%"
                >
                  <el-option
                      v-for="item in genderOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="学历"
                  prop="education"
              >
                <el-input
                    v-model="staffForm.education"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
              label="电话"
              prop="phone"
          >
            <el-input v-model="staffForm.phone"/>
          </el-form-item>

          <el-form-item
              label="邮箱"
              prop="email"
          >
            <el-input v-model="staffForm.email"/>
          </el-form-item>

          <el-form-item
              label="地址"
              prop="address"
          >
            <el-input v-model="staffForm.address"/>
          </el-form-item>

          <el-form-item
              label="入职时间"
              prop="hireDate"
          >
            <el-date-picker
                v-model="staffForm.hireDate"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
            />
          </el-form-item>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                  label="在职状况"
                  prop="employmentStatus"
              >
                <el-select
                    v-model="staffForm.employmentStatus"
                    class="m-2"
                    placeholder="请选择"
                    style="width:100%"
                >
                  <el-option
                      v-for="item in employmentStatusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="使用状态"
                  prop="status"
              >
                <el-select
                    v-model="staffForm.status"
                    class="m-2"
                    placeholder="请选择"
                    style="width:100%"
                >
                  <el-option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
              label="头像"
              prop="avatar"
          >
            <Upload
                :avatar="staffForm.avatar"
                @change="handleChange"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
              label="个人简历"
              prop="biography"
          >
            <el-input
                type="textarea"
                v-model="staffForm.biography"
                class="fixed-height-textarea"
            />
          </el-form-item>

          <el-form-item
              label="分工"
              prop="responsibility"
          >
            <el-input
                type="textarea"
                v-model="staffForm.responsibility"
                class="fixed-height-textarea"
            />
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                @click="submitForm()"
                :loading="loading"
            >
              更新
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import Upload from "@/components/upload/Upload.vue";
import { isEqual } from 'lodash';
import { useRoute, useRouter } from 'vue-router'
import {ElMessage} from "element-plus";
import {uploadToAliyun} from "@/utils/uploadService";

const loading = ref(false);
const router = useRouter();
const route = useRoute();
const staffFormRef = ref();
const staffForm = reactive({
  name: "",
  phone: "",
  position: "",
  email: "",
  address: "",
  hireDate: "",
  education: "",
  biography: "",
  responsibility: "",
  employmentStatus: 0,
  gender: 0,
  avatar: "",
  // file: null
});

// 原始数据
const originalStaffForm = reactive({});

// 表单验证规则
const staffFormRules = reactive({
  name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
  gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
  phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
  hireDate: [{ required: true, message: "请输入入职时间", trigger: "blur" }],
  employmentStatus: [{ required: true, message: "请输入在职情况", trigger: "blur" }],
  status: [{ required: true, message: "请选择使用状态", trigger: "blur" }],
  avatar: [{ required: true, message: "请选择头像", trigger: "blur" }]
});

// 在职状况选择
const employmentStatusOptions = [
  { label: "在职", value: 0 },
  { label: "借调", value: 1 },
  { label: "休假", value: 2 },
  { label: "退休", value: 3 },
  { label: "死亡", value: 4 }
];

// 人员使用状态选择
const statusOptions = [
  { label: "启用", value: 1 },
  { label: "停用", value: 0 }
];

// 性别选择
const genderOptions = [
  { label: "保密", value: 0 },
  { label: "男", value: 1 },
  { label: "女", value: 2 }
];

// 每次选择完图片之后的回调
const handleChange = file => {
  staffForm.avatar = URL.createObjectURL(file);
  staffForm.file = file;
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 获取当前页的数据
onMounted(async () => {
  const res = await axios.get(`${baseUrl}/adminapi/staff/list/${route.params.id}`);
  Object.assign(staffForm, res.data.data[0]);
  Object.assign(originalStaffForm, res.data.data[0]);
});

// 更新提交表单
const submitForm = async () => {
  staffFormRef.value.validate(async valid => {
    if (valid) {
      // 比较表单数据和原始数据
      if (isEqual(staffForm, originalStaffForm)) {
        ElMessage.warning("表单数据未修改，无需提交");
        return;
      }

      // 如果更新了头像，更新 staffForm 中的文件路径为阿里云返回的文件 URL
      if (staffForm.file) {
        staffForm.avatar = await uploadToAliyun('staffuploads', staffForm.file);
        delete staffForm.file;
      }

      try {
        const res = await axios.post(`${baseUrl}/adminapi/staff/edit`, staffForm);
        if (res.data.ActionType === "OK") {
          // 更新原始表单数据
          Object.assign(originalStaffForm, staffForm);
          ElMessage.success("更新成功");
          await router.push(`/staff-manage/stafflist`);
        } else {
          ElMessage.error("更新失败");
        }
      } catch (error) {
        ElMessage.error("请求失败，请重试");
      }
    }
  });
};

const handleBack = () => {
  router.back();
}
</script>

<style lang="scss" scoped>
.ruleForm {
  margin-top: 30px;

  button {
    margin-left: auto;
  }

  ::v-deep(.el-textarea__inner) {
    height: 198px;
    resize: none
  }
}
</style>
