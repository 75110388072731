<template>
  <el-page-header
      content="更换图片"
      @back="handleBack()"
      title="图片管理"
      style="margin-bottom: 20px;"
  />
  <CarouselForm :id="route.params.id"/>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import CarouselForm from "@/views/picture-manage/CarouselForm.vue";

const router = useRouter()
const route = useRoute()

const handleBack = () => {
  router.back()
}
</script>

<style lang="scss" scoped>

</style>
