<template>
  <div>
    <el-page-header
        content="个人中心"
        icon=""
        title="网站后台管理系统"
    />

    <el-row
        :gutter="20"
        class="el-row"
    >
      <el-col :span="7">
        <el-card class="box-card">
          <el-avatar
              :size="150"
              :src="avatarUrl"
          />
          <h3>{{ store.state.userInfo.username }}</h3>
          <h5>{{ getDefaultRole(store.state.userInfo.role) }}</h5>
          <h5>登录次数</h5>
          <h5>{{ store.state.userInfo.logins }}</h5>
          <h5>上次登录时间</h5>
          <h5>{{ formatTime.getTime(store.state.userInfo.updatedAt) }}</h5>
        </el-card>
      </el-col>
      <el-col :span="17">
        <el-card>
          <template #header>
            <div class="card-header">
              <span>个人信息</span>
            </div>
          </template>

          <el-form
              ref="userFormRef"
              :model="userForm"
              :rules="userFormRules"
              label-width="120px"
              class="ruleForm"
          >
            <el-form-item
                label="用户名"
                prop="username"
            >
              <el-input v-model="userForm.username" disabled/>
            </el-form-item>

            <el-form-item
                label="昵称"
                prop="nickname"
            >
              <el-input v-model="userForm.nickname"/>
            </el-form-item>

            <el-form-item
                label="性别"
                prop="gender"
            >
              <el-select
                  v-model="userForm.gender"
                  class="m-2"
                  placeholder="Select"
                  style="width:100%"
              >
                <el-option
                    v-for="item in genderOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
                label="密码"
                prop="password"
            >
              <el-input v-model="userForm.password" type="password"/>
            </el-form-item>

            <el-form-item
                label="职责"
                prop="responsibility"
            >
              <el-input
                  v-model="userForm.responsibility"
                  type="textarea"
                  disabled/> <!-- 禁用职责输入框 -->
            </el-form-item>

            <el-form-item
                label="头像"
                prop="avatar"
            >
              <Upload
                  :avatar="userForm.avatar"
                  @change="handleChange"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                  type="primary"
                  @click="submitForm()"
              >更新
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed, ref, reactive} from "vue";

import {ElMessage} from "element-plus";
import {getDefaultAvatar} from "@/utils/getDefaultAvatar";
import {getDefaultRole} from "@/utils/getDefaultRole";
import formatTime from "@/utils/formatTime";
import {uploadToAliyun} from "@/utils/uploadService";
import Upload from "@/components/upload/Upload.vue";
import axios from "axios";

const store = useStore();

const avatarUrl = computed(
    () =>
        store.state.userInfo.avatar
            ? store.state.userInfo.avatar
            : getDefaultAvatar(store.state.userInfo.gender)
);

const {_id, username, gender, responsibility, avatar, nickname} = store.state.userInfo;
const userFormRef = ref();
const userForm = reactive({
  username,
  gender,
  responsibility,
  password: "",
  avatar,
  nickname,
  _id,
});

// 表单验证
const userFormRules = reactive({
  nickname: [],
  gender: [{required: true, message: "请选择性别", trigger: "blur"}],
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, message: "密码长度不少于8个字符", trigger: "blur" }
  ],
  avatar: [{required: true, message: "请上传头像", trigger: "blur"}]
});

//性别选择
const genderOptions = [
  {label: "保密", value: 0},
  {label: "男", value: 1},
  {label: "女", value: 2}
];

//每次选择完图片之后的回调
const handleChange = file => {
  userForm.avatar = URL.createObjectURL(file);
  userForm.file = file;
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

//更新提交
const submitForm = async () => {
  userFormRef.value.validate(async valid => {
    if (valid) {
      // 如果更新了头像，更新 userForm 中的文件路径为阿里云返回的文件 URL
      if (userForm.file) {
        userForm.avatar = await uploadToAliyun('useruploads', userForm.file);
      }

      delete userForm.file;

      try {
        const res = await axios.post(`${baseUrl}/adminapi/user/edit/${userForm._id}`, userForm);
        if (res.data.ActionType === "OK") {
          store.commit("changeUserInfo", res.data.data);
          userForm.password = "";
          ElMessage.success("更新成功");
        } else {
          ElMessage.error("更新失败");
        }
      } catch (error) {
        ElMessage.error("请求失败，请重试");
      }
    }
  });
};
</script>

<style scoped lang="scss">
.el-row {
  margin-top: 20px;

  .box-card {
    text-align: center;
  }

  h5 {
    margin-top: 8px;
  }
}

</style>
