<template>
  <div class="container">
    <el-page-header
        :content="departmentName ? '调整 ' + departmentName + ' 编制' : '调整编制'"
        @back="handleBack()"
        title="编制管理"
    />
    <div class="form-container">
      <div class="staff-container">
        <staffCheckbox
            :departmentId="departmentId"
            :departmentName="departmentName"
            :departmentStaffs="departmentStaffIds"
            :path="'/establishment-manage/establishmentList'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import StaffCheckbox from "@/views/establishment-manage/staffCheckbox.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

// 接收父组件传递的参数
const departmentId = ref('');
const departmentName = ref('');
const departmentStaffIds = ref([]);

onMounted(() => {
  // 从路由查询参数中获取数据
  departmentId.value = route.query.departmentId;
  departmentName.value = route.query.departmentName;
  departmentStaffIds.value = route.query.departmentStaffIds;
});

const handleBack = () => {
  router.push({
    path: '/establishment-manage/establishmentList',
  });
}
</script>

<style lang="scss" scoped>
.container {
  button {
    display: block;
    margin: 16px 0 0 auto;
  }

  .form-container {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }

  .staff-container{
    width: 100%;
    height: 768px;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
