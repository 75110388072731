<!--登录页面-->
<template>
  <div class="login-container"
      :style="{backgroundImage: 'url(/logo.png)'}"
  >
    <!-- 登录表单容器 -->
    <div class="formContainer">
      <h3>网站后台管理系统</h3>
      <el-form
          ref="loginFormRef"
          :model="loginForm"
          status-icon
          :rules="loginRules"
          label-width="80px"
          class="loginform"
      >
        <!-- 用户名输入框 -->
        <el-form-item
            label="用户名"
            prop="username"
        >
          <el-input
              v-model="loginForm.username"
              autocomplete="off"
          />
        </el-form-item>
        <!-- 密码输入框 -->
        <el-form-item
            label="密码"
            prop="password"
        >
          <el-input
              v-model="loginForm.password"
              type="password"
              autocomplete="off"
          />
        </el-form-item>
        <!-- 登录按钮 -->
        <el-form-item>
          <el-button
              type="primary"
              @click="submitForm()"
          >登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import {reactive, ref} from 'vue'
import {useRouter} from 'vue-router'
import axios from 'axios'
import {ElMessage} from 'element-plus'
import {useStore} from 'vuex'
// import bcrypt from "bcryptjs";

const store = useStore()
// 表单绑定的响应式对象
const loginForm = reactive({
  username: "",
  password: ""
})

const loginFormRef = ref() //表单地引用对象
// 表单验证规则
const loginRules = reactive({
  username: [
    {
      required: true,
      message: "请输入用户名",
      trigger: "blur"
    }
  ],
  password: [
    {
      required: true,
      message: "请输入密码",
      trigger: "blur"
    }
  ]
})
const router = useRouter()
// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

//提交表单函数
const submitForm = async () => {
  // 1. 校验表单
  loginFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        // 发送登录请求
        const res = await axios.post(baseUrl + "/adminapi/user/login", loginForm);
        if (res.data.ActionType === "OK") {
          // 更新用户信息
          store.commit("changeUserInfo", res.data.data);
          store.commit("changeGetterRouter", false);
          await router.push("/index");
        } else {
          if (res.data.code === "-1") {
            ElMessage.error(res.data.error);
          }
        }
      } catch (error) {
        ElMessage.error('登录失败');
      }
    }
  })
}
</script>

<style lang="scss" scoped>
.login-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  width: 500px;
  height: 300px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.6);
  color: white;
  text-align: center;
  padding: 20px;

  h3 {
    font-size: 30px;
  }

  .el-button {
    margin: 20px 0 0 318px;
  }

  .loginform {
    margin-top: 20px;
  }
}

::v-deep(.el-form-item__label) {
  color: white;
}
</style>    
