<template>
  <el-page-header
      content="编辑专题"
      @back="handleBack()"
      title="新闻管理"
      style="margin-bottom: 20px;"
  />
  <NewsHotForm :id="route.params.id"/>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import NewsHotForm from "@/views/news-manage/NewsHotForm.vue";

const router = useRouter()
const route = useRoute()

const handleBack = () => {
  router.back()
}
</script>

<style lang="scss" scoped>

</style>
