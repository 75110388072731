<template>
  <div>
    <el-page-header
        content="部门列表"
        icon=""
        title="部门管理"
    />
    <el-card class="table-container">
      <el-table
          :data="tableData"
          style="width: 100%;"
      >
        <el-table-column
            prop="sortOrder"
            label="序号"
            width="60"
        />

        <el-table-column
            prop="name"
            label="部门"
            width="170"
        />

        <el-table-column
            prop="func"
            label="部门职能"
        />

        <el-table-column
            prop="director"
            label="部门负责人"
            width="98"
        />

        <el-table-column
            prop="directorPosition"
            label="职务"
            width="100"
        />

        <el-table-column label="是否启用" width="92">
          <template #default="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleSwitchChange(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template #default="scope">
            <el-button
                circle
                :icon="Star"
                type="success"
                @click="handlePreview(scope.row)"
            ></el-button>
            <el-button
                circle
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>

            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    circle
                    :icon="Delete"
                    type="danger"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
        v-model="dialogVisible"
        title="预览部门"
        width="50%"
    >
      <div>
        <h2>{{ previewData.name }}</h2>
        <div style="font-size:12px;color:gray;">{{ "最后更新时间：" + formatTime.getTime(previewData.editTime) }}</div>

        <el-divider>
          <el-icon>
            <star-filled/>
          </el-icon>
        </el-divider>

        <div class="htmlcontent">
          <strong>职能：</strong> {{ previewData.func }}
          <div>
            <strong>职能：</strong> {{ previewData.func }}
          </div>
          <div>
            <strong>负责人：</strong> {{ previewData.director }} {{ previewData.directorPosition }}
          </div>
          <div>
            <strong>第二负责人：</strong> {{ previewData.deputy }} {{ previewData.deputyPosition }}
          </div>
          <div>
            <strong>电话：</strong> {{ previewData.phone }}
          </div>
          <div>
            <strong>地址：</strong> {{ previewData.address }}
          </div>
          <div>
            <strong>邮箱：</strong> {{ previewData.email }}
          </div>
          <div>
            <strong>状态：</strong> {{ getStatusText(previewData.status) }}
          </div>
          <div>
            <strong>排序顺序：</strong> {{ previewData.sortOrder }}
          </div>
          <div>
            <strong>创建时间：</strong> {{ formatTime.getTime(previewData.createTime) }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import formatTime from "@/utils/formatTime";
import {ElDialog, ElDivider, ElIcon} from 'element-plus';
import {Star, Edit, Delete, StarFilled} from '@element-plus/icons-vue';
import {useRouter} from 'vue-router'

const router = useRouter()
const tableData = ref([]);
const previewData = ref({});
const dialogVisible = ref(false);

onMounted(() => {
  getTableData();
});

const getStatusText = (status) => {
  return status === 1 ? '启动' : '禁用';
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(`${baseUrl}/adminapi/department/list`);
  tableData.value = res.data.data;
};


//开关回调
const handleSwitchChange = async item => {
  await axios.put(`${baseUrl}/adminapi/department/publist`, {
    _id: item._id,
    status: item.status
  });
  await getTableData();
};

//预览回調
const handlePreview = data => {
  previewData.value = data;
  dialogVisible.value = true;
};

//删除回调
const handleDelete = async (item) => {
  await axios.delete(`${baseUrl}/adminapi/department/list/${item._id}`)
  await getTableData()
}

//编辑回调
const handleEdit = (item) => {
  //跳转到/department-manage/editdepartment/:id
  router.push(`/department-manage/editdepartment/${item._id}`)
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 10px;
}

.table-container {
  margin-top: 20px;

  :deep(.el-card__body) {
    padding: 10px 20px 20px 20px;
  }
}

.htmlcontent {
  div {
    margin-bottom: 10px;
    font-size: 15px;
  }
}

::v-deep(.htmlcontent) {
  img {
    max-width: 100%;
  }
}
</style>
